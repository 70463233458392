<template>
  <v-navigation-drawer
      :value="getToggleSlider"
      :key="`offer-1`"
      fixed
      temporary
      :stateless="keepOpen"
      overlay-opacity="0.8"
      right
      width="80%"
      class="fill-height"
  >

    <v-toolbar outlined flat dense light class="navbar-fixed-header">
      <v-btn icon class="mr-1" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-divider vertical></v-divider>
      <h5 class="ml-5 text-uppercase" v-if="getActiveComponent === 'hotel'">{{ getIsVendorUpdate === true ? 'Edit' : 'Add' }} Hotel</h5>
      <h5 class="ml-5 text-uppercase" v-else-if="getActiveComponent === 'location'">{{ getIsVendorUpdate === true ? 'Edit' : 'Add' }} Venue</h5>
      <v-spacer></v-spacer>
    </v-toolbar>

    <choose-category :form="form" v-if="getActiveComponent === 'home'" @loadComponent="loadComponent"></choose-category>
    <new-hotel @showMessage="showMessage" :form="form" v-else-if="getActiveComponent === 'hotel'"></new-hotel>
    <new-location @showMessage="showMessage" :form="form" v-else-if="getActiveComponent === 'location'"></new-location>

  </v-navigation-drawer>
</template>

<script>

const ChooseCategory = () => import(/* webpackChunkName: "choose-category" */'@/components/ChooseCategory');
const NewHotel = () => import(/* webpackChunkName: "new-hotel" */'./hotels/NewHotel');
const NewLocation = () => import(/* webpackChunkName: "new-location" */'./locations/NewLocation');

import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "NewVendor",
  components: { ChooseCategory, NewHotel, NewLocation },
  props: ["form"],
  data: () => ({
    validationMessage: '',
  }),
  computed: {
    keepOpen() { return true; },
    ...mapGetters(['getToggleSlider', 'getActiveComponent', 'getIsVendorUpdate']),
  },
  methods: {
    showMessage(snackbar={}) {
      this.$emit('showMessage', snackbar);
    },
    close() {
      this.setToggleSlider(false);
      //this.activeComponent = 'home';
      this.setActiveComponent('home');
    },
    loadComponent( type='hotel' ) {
      this.$emit('loadComponent', type);
      //this.activeComponent = type;
      this.setActiveComponent(type);
    },
    ...mapMutations(['setToggleSlider', 'setActiveComponent']),
  },
}
</script>
<style scoped>
.navbar-fixed-header {
  position: fixed;
  z-index: 11;
  width: 100%;
}
</style>